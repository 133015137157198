// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://api.procellrepair.com',
  //  //apiBaseUrl: 'https://api.procellrepair.com',
  //apiBaseUrl: 'https://127.0.0.1:8000',

  imgBaseUrl: 'device-img',
  imgBaseUrlBrands: 'brands',

  //googleClientId: '111734977550-90be0oegmgc2umdm7sqdtiokfj7l01ab.apps.googleusercontent.com',
  googleClientId: '194266838484-ac1knucs3hiblq03mj3hjk4dt4igpmum.apps.googleusercontent.com',
  facebookClientId: '1008957526625805',
  //facebookClientId: '3179495395670949',
  // facebookClientId: '930888950836124',
  // facebookClientId: '1008957526625805',
  twitterProfile: 'MiamiProcell'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
