import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {AuthUser} from "../interfaces/auth-user";
import {AuthCredentials} from "../interfaces/auth-credentials";
import {AuthResponse} from "../interfaces/auth-response";


@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  static authTokenStorageKey = 'auth_token';
  static authUserStorageKey = 'auth_user';
  static orderUrlKey = 'order_url';

  private isAuthenticatedSubject = new ReplaySubject<boolean>(0);

  private authTokenSubject = new BehaviorSubject<string|null>(null);

  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  authToken$ = this.authTokenSubject.asObservable();

  get authToken(): string | null {
    try {
      return localStorage.getItem(AuthService.authTokenStorageKey);
    } catch {
      return null;
    }
  }

  set authToken(token: string | null) {
    localStorage.setItem(AuthService.authTokenStorageKey, token as string);
  }

  get authUser(): AuthUser | null {
    try {
      return JSON.parse(localStorage.getItem(AuthService.authUserStorageKey) as string) || null;
    } catch {
      return null;
    }
  }

  set authUser(authUser: AuthUser | null) {
    localStorage.setItem(AuthService.authUserStorageKey, JSON.stringify(authUser));
  }

  get orderUrl(): string|null {
    try {
      return localStorage.getItem(AuthService.orderUrlKey);
    } catch {
      return null;
    }
  }

  set orderUrl(url: string | null) {
    localStorage.setItem(AuthService.orderUrlKey, url as string);
  }

  get isLogin(): boolean {
    return !!this.authToken;
  }

  constructor(private httpClient: HttpClient, @Inject(PLATFORM_ID) private platformId: object|any) {
    this.isAuthenticatedSubject.next(this.isLogin);
    this.authTokenSubject.next(this.authToken);
  }

  ngOnDestroy(): void {
    this.isAuthenticatedSubject.complete();
  }

  login(credentials: AuthCredentials, path?: string): Observable<AuthResponse> {
    return this.httpClient
      .post(!path
          ? `${environment.apiBaseUrl}/api/app/authentication_token` : `${environment.apiBaseUrl}${path}`,
          JSON.stringify(credentials
      ), {
        headers: new HttpHeaders({
          Accept: 'application/ld+json',
          'Content-Type': 'application/json'
        }),
        observe: 'body'
      })
      .pipe(
        map((response: AuthResponse | any) => {
          return this.manualLogin(response);
        })
      );
  }

  manualLogin(authResponse: AuthResponse | any): AuthResponse {
    this.authToken = authResponse.token;

    this.authUser = {
      id: authResponse.id,
      fullName: authResponse.fullName,
      username: authResponse.username,
      email: authResponse.email,
      phone: authResponse.phone,
      context: authResponse.context
    };

    this.isAuthenticatedSubject.next(true);
    this.authTokenSubject.next(authResponse.token);

    return authResponse;
  }

  logout(): void {
    if (isPlatformBrowser(this.platformId)) {
      try {
        window.localStorage.removeItem(AuthService.authTokenStorageKey);
        window.localStorage.removeItem(AuthService.authUserStorageKey);
      } catch { }
    }

    this.isAuthenticatedSubject.next(false);
    this.authTokenSubject.next(null);
  }

  removeOrderUrl(): void {
    if (isPlatformBrowser(this.platformId)) {
      try {
        window.localStorage.removeItem(AuthService.orderUrlKey);
      } catch { }
    }
  }
}
