import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const INTERNAL_SERVER_ERROR = 500;

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  apiPrefix = `${environment.apiBaseUrl}`;

  authToken = '';

  constructor(public httpClient: HttpClient, private authService: AuthService) {
    this.authService.authToken$.subscribe((token: string|null) => {
      this.authToken = token ? `Bearer ${token}` : '';
    });
  }

  getApiPrefix() : string {
    return this.apiPrefix;
  }

  get<T>(path: string, httpParams?: HttpParams | {}, headerAccept?: string): Observable<any> {
    const headers = new HttpHeaders({
        Accept: headerAccept ?? 'application/ld+json',
        Authorization: this.authToken,
      });
    return this.httpClient.get(this.apiPrefix + path, {
      headers,
      params: httpParams
    });
  }

  post(path: string, body: any = {}, httpParams?: HttpParams | {} ): Observable<any> {
    return this.httpClient.post(this.apiPrefix + path, JSON.stringify(body), {
      params: httpParams,
      headers: new HttpHeaders({
        Accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
        Authorization: this.authToken,
      })
    });
  }

  put(path: string, body: any = {}, httpParams?: HttpParams | {} ): Observable<any> {
    return this.httpClient.put(this.apiPrefix + path, JSON.stringify(body), {
      params: httpParams,
      headers: new HttpHeaders({
        Accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
        Authorization: this.authToken,
      })
    });
  }

  patch(path: string, body: any = {}, httpParams?: HttpParams | {} ): Observable<any> {
    return this.httpClient.patch(this.apiPrefix + path, JSON.stringify(body), {
      params: httpParams,
      headers: new HttpHeaders({
        Accept: 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
        Authorization: this.authToken,
      })
    });
  }

  delete(path: string, httpParams?: HttpParams | {} ): Observable<any> {
    return this.httpClient.delete(this.apiPrefix + path, {
      headers: new HttpHeaders({
        Authorization: this.authToken
      }),
      params: httpParams
    });
  }

  upload(path: string, formData: FormData, httpParams?: HttpParams | {}): Observable<any> {
    return this.httpClient.post(this.apiPrefix + path, formData, {
      headers: new HttpHeaders({
        Accept: 'application/ld+json',
        Authorization: this.authToken
      }),
      params: httpParams
    });
  }
}
